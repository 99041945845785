var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"450","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('msg.update_application_details')))]),_c('v-card-text',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var failed = ref.failed;
var passes = ref.passes;
return [_c('form',[_c('ValidationProvider',{ref:"observerEditApplicationNumber",attrs:{"vid":"applicationNumber","name":"applicationNumber","rules":{required: _vm.application.applicationStatus === 'A', numeric:true, max:10 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{class:{'requiredSticker': _vm.application.applicationStatus === 'A'},attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'applicationNumber'),"label":_vm.$t('lbl.application_number')},model:{value:(_vm.application.applicationNumber),callback:function ($$v) {_vm.$set(_vm.application, "applicationNumber", $$v)},expression:"application.applicationNumber"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-select',{attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'customerApplicationStatus'),"items":_vm.$parent.filters.applicationStatus,"item-text":"text","item-value":"value","label":_vm.$t('lbl.application_status')},on:{"change":_vm.onStatusChanged},model:{value:(_vm.application.applicationStatus),callback:function ($$v) {_vm.$set(_vm.application, "applicationStatus", $$v)},expression:"application.applicationStatus"}})]}}],null,true)}),(_vm.application.applicationStatus === 'A')?_c('div',[_c('ValidationProvider',{attrs:{"rules":{numeric:true, required: true, min_value: _vm.validation.minAmount, max_value: _vm.validation.maxAmount }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'financingAmountApproved', [_vm.currency, _vm.validation.minAmount, _vm.validation.maxAmount]),"label":_vm.$t('lbl.financing_amount_approved'),"prefix":_vm.currency,"required":""},model:{value:(_vm.application.financingAmountApproved),callback:function ($$v) {_vm.$set(_vm.application, "financingAmountApproved", $$v)},expression:"application.financingAmountApproved"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{numeric:true, required: true, min_value: _vm.validation.minTenure, max_value: _vm.validation.maxTenure }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'tenureApproved', [_vm.validation.minTenure, _vm.validation.maxTenure]),"label":_vm.$t('lbl.financing_tenure_approved'),"suffix":"Months","required":""},model:{value:(_vm.application.tenureApproved),callback:function ($$v) {_vm.$set(_vm.application, "tenureApproved", $$v)},expression:"application.tenureApproved"}})]}}],null,true)})],1):_vm._e(),_c('ValidationProvider',{attrs:{"rules":{required: false, min : 1, max : 3, regex:/^[a-zA-Z0-9\s-+#@]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$translatedErrorMessages(failedRules,'internalRating'),"label":"Internal Credit Rating"},model:{value:(_vm.application.internalRating),callback:function ($$v) {_vm.$set(_vm.application, "internalRating", $$v)},expression:"application.internalRating"}})]}}],null,true)})],1),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.saveError),expression:"saveError"}]},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('msg.failed_action')))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.serverError),expression:"serverError"}]},[_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('error.server')))])])]),_c('v-card-actions',{attrs:{"align":"right"}},[_c('v-spacer'),_c('v-skeleton-loader',{staticClass:"mr-3",attrs:{"loading":_vm.loader,"transition":"scale-transition","type":"button"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('cancel')))])],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loader,"transition":"scale-transition","type":"button"}},[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return passes(_vm.updateApplicationStatus)}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }